.site-menu-list-item-search img {
  width: 23px;
}

.site-menu-list-item-account img {
  width: 19px;
}

.site-menu-list img {
  /* width: 30px; */
  position: relative;
  opacity: 0.7;
  top: -3px;
  /* top: 35px; */
}

.site-menu-last-main-menu-item {
  margin-right: 2%;
}

body.mce-content-body .tab-content {
    display: block;
}

body.template-login_form div#content-core {
  padding: 0px 60px;
}

div#login-form form {
  margin: 20px 0;
}
div#login-form form label {
    width: 100px;
    text-align: right;
    display: inline-block;
}
div#login-form form input,
div#login-form form div.widget {
  width: 300px;
  display: inline-block;
}
div#login-form form input {
  margin-left: 20px;
}
div#login-form form div.formControls {
    width: 400px;
    margin-top: 20px;
    text-align: right;
}
div#login-form form div.formControls input {
    width: auto;
} 

table.listing {
  margin-top: 30px;
  background-color: #eee;
  padding: 20px;
}
table.listing tr th,
table.listing tr td {
  padding: 5px 30px;
}


#content.bootstrap-scope a {
  text-decoration: none;
  color: #e25a48;
}
.plone-modal-header {
  margin-bottom: 5px;
  height: 25px;
}
